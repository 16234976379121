import React from 'react'
import Footer from '../../Footer/footer'

const Solution = () => {
  return (
    <div className='container-solution'>
      <div className='wrapper-beranda'>
        <div className='hero-beranda'>
                <h1>solution</h1>
              <div className='hero-img'>
              </div>
        </div>
      </div>
          <Footer />
    </div>
  )
}

export default Solution
