import React from 'react'

const Footer = () => {
  return (
    <div className='container-footer'>
        <div className='wrapper-footer'>
            <div className='logo-footer'>
                <h1>Wealthy Society</h1>
            </div>
            <div className='list-footer'>
                <div className='more-footer'>
                    <h2>More</h2>
                        <div className='feature-footer'>
                            <li><a href='/blog'>Blog</a></li>   
                        </div>
                </div>

                <div className='touch-footer'>
                    <h2>Get in touch</h2>
                    <div className='feature-footer'>
                        <li><a href='/blog'>Blog</a></li>
                        <li><a href='/'>Press</a></li>
                        <li><a href='/'>Contact</a></li>
                        <li><a href='/'>About</a></li>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
  )
}

export default Footer
