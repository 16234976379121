import React, { useRef } from 'react'
import '../../Assets/Style/main.css'
import '../../Assets/Style/main-mobile.css'

import useOutsideClick from '../../Hook/useOutsideClick'

// import {GiCrossedBones} from 'react-icons/gi'
import Modirective from './mo-directive'

const Menu = () => {


  return (
    <div>
    <Modirective />
    <div className='wrappers-directive'>
        <nav className='lists-menu'>
          <li><a href='/'>Beranda</a></li>
          <li><a href='/solution'>Solution</a></li>
          <li><a href='/blog'>Blog</a></li>
          <li><a href='/contact'>Contact</a></li>
        </nav>
      </div>
      </div>
  )
}

export default Menu