import React from 'react'
import { Link } from 'react-router-dom'
import Modirective from '../../Helper/Modirective/mo-directive'

const Directive = () => {

  return (
    <div className={'sticky ? "sticky" : " "'}>
      <div className='wrapper-directive'>
        <h1>Wealthy Society</h1>
        <nav className='list-menu'>
          <li><a href='/'>Beranda</a></li>
          <li><a href='/solution'>Solution</a></li>
          <li><a href='/blog'>Blog</a></li>
          <li><a href='/contact'>Contact</a></li>
        </nav>
        <Modirective/>
      </div>
    </div>
  )
}

export default Directive
