import React from 'react'
import heroberanda from '../../../Assets/Image/heroberanda2.png'
import Footer from '../../Footer/footer'

const Beranda = () => {
  return (
    <div className='container-beranda'>
    <div className='wrapper-beranda'>
       <div className='hero-beranda'>
          <div className='box-txt'>
              <h1 data-aos='fade-right' className='hero-txt' >Pembaharuan ekosistem technology, Mengadaptasi 4.0</h1>
          </div>
          <div data-aos='fade-in' className='hero-img'>
              <img src={heroberanda} alt='heroberanda'/>
          </div>
       </div>
    </div>
        <Footer />
    </div>
  )
}

export default Beranda