import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './Libs/Assets/Style/main.css'
import './Libs/Assets/Style/main-mobile.css'

import Beranda from './Libs/Components/Sub-district/Beranda/beranda'
import Blog from './Libs/Components/Sub-district/Blog/blog'
import Solution from './Libs/Components/Sub-district/Solution/solution'
import Contact from './Libs/Components/Sub-district/Contact/contact'

import Directive from './Libs/Components/Directive/directive';
import Footer from "./Libs/Components/Footer/footer";


function App() {
  return (
    <Router>
      <Directive />
        <Switch>
            <Route path='/' component={Beranda} exact><Beranda /></Route>
            <Route path='/solution' component={Solution} exact><Solution /></Route>
            <Route path='/blog' component={Blog} exact><Blog /></Route>
            <Route path='/contact' component={Contact} exact><Contact /></Route>
        </Switch>
    </Router>
  )
}

export default App;
