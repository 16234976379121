import React, { useRef, useState } from 'react'
import useOutsideClick from '../../Hook/useOutsideClick'
import '../../Assets/Style/main.css'
import '../../Assets/Style/main-mobile.css'
import Menu from './menu'

const Modirective = () => {


  const [ showMenu , setShowMenu ] = useState(false);
  
  let menu

  if (showMenu) {
    menu = <Menu />
  }

  const ref = useRef();

  useOutsideClick(ref, () => {
    setShowMenu(false)
  });

  return (
    <nav ref={ref} onClick={() => setShowMenu(!showMenu)}  className='toggel-menu'>
        <span></span>
        <span></span>
        <span></span>
        
        {menu}
    </nav>
  )
}

export default Modirective